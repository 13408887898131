import { FieldArrayWithId } from 'react-hook-form'

import { TimeEntryReturn } from 'API/TimeCard/GraphQL'
import { TimeoffsByCursorNode } from 'API/Timeoffs/GraphQL'
import { DateTime, Interval } from 'luxon'
import { JobEmployee } from 'Types/app'
import { SelectOption, SelectOptionWithColor } from 'Types/common'

import { compact, pick, toNumber, trim } from 'lodash'

import { shiftTagToOption } from 'components/blocks/__v3__/ShiftTagsPickerV2/mappers'
import { timeTypeToOption } from 'components/blocks/__v3__/TimeTypesPicker/helpers'

import { TimeEntryKind } from 'constants/gatewayGraphQL'

import { AppContextCompany } from 'services/AppContext'
import Utils from 'services/Utils'

import { earningTypeToOption } from '../../EarningTypePicker/helpers'
import {
  DraftTimesheetRecord,
  ExistingTimesheetRecord,
  FieldArrayItemWithInitialIndex,
  FormTimesheetRecord,
  PositionData,
  PositionOption,
  TimesheetFormState,
} from '../types'

export const FULL_DAY_SECONDS = 24 * 60 * 60

export function minutesToOption(minutes: number): SelectOption<number> {
  return {
    value: minutes,
    label: `${minutes} min`,
  }
}

export function serverToForm(
  timeEntry: TimeEntryReturn,
  company: AppContextCompany,
): ExistingTimesheetRecord {
  const { location, department, job, earningType } = timeEntry

  const startDateTime = DateTime.fromISO(timeEntry.startAt, {
    zone: location.settings.timezone,
  })

  const endDateTime = DateTime.fromISO(timeEntry.endAt, {
    zone: location.settings.timezone,
  })

  const startOfStartDay = startDateTime.startOf('day')

  const startSeconds = startDateTime.diff(startOfStartDay, 'seconds').seconds
  const endSeconds = (endDateTime.hasSame(startDateTime, 'day')
    ? endDateTime.diff(startOfStartDay, 'seconds')
    : endDateTime.diff(endDateTime.startOf('day'), 'seconds')
  ).seconds

  const hasTimeBucket = !!timeEntry.timeBucketChild

  const timeBucketChild = hasTimeBucket
    ? {
        label: Utils.TimeBucket.getTimeBucketJoinedName({
          timeBucketChild: timeEntry.timeBucketChild,
        }),
        value: timeEntry.timeBucketChild.id,
        timeBucketChild: timeEntry.timeBucketChild,
      }
    : null

  const { shiftTags, timetype, breakSeconds, id } =
    timeEntry.timeEntryTimeRanges[0] ?? {}

  return {
    id: timeEntry.id,
    startDate: startOfStartDay,
    startSeconds,
    endSeconds,
    employeeId: timeEntry.employee.id,
    position: generatePositionOptions({ location, department, job }),
    earningType: earningType
      ? earningTypeToOption(company.identity)(earningType)
      : null,
    timeType: timetype ? timeTypeToOption(timetype) : null,
    shiftTags: shiftTags?.map(shiftTag => shiftTagToOption(shiftTag)) ?? null,
    activityDetails: timeEntry.activityDetails,
    state: timeEntry.state,
    submittedAt: timeEntry.submittedAt,
    timeBucketChild,
    mealBreak: breakSeconds
      ? minutesToOption(Math.floor(breakSeconds / 60))
      : null,
    // Temporary field to be removed once UI supports multiple time ranges
    timeEntryTimeRangeId: id,
  }
}

function generatePositionOptions({
  location,
  department,
  job,
}: PositionData): PositionOption {
  return {
    value: `${location.id}_${department.id}_${job.id}`,
    label: `${department.name} / ${job.name}`,
    data: {
      location: {
        ...pick(location, ['id', 'name']),
        timezone: location.settings.timezone,
      },
      department: pick(department, ['id', 'name']),
      job: pick(job, ['id', 'name']),
    },
  }
}

export function formItemToCreatePayload(
  item: DraftTimesheetRecord,
  company: AppContextCompany,
): Gateway.CreateTimeEntryPayload {
  const { startAt, endAt } = secondsToStartEnd(item)
  const {
    employeeId,
    earningType,
    activityDetails,
    timeBucketChild,
    timeType,
    mealBreak,
    shiftTags,
  } = item
  const { location, department, job } = item.position.data

  return {
    locationId: toNumber(location.id),
    departmentId: toNumber(department.id),
    roleId: toNumber(job.id),
    employeeId,
    startAt: startAt.toISO(),
    endAt: endAt.toISO(),
    kind: TimeEntryKind.WeeklyTimesheet,
    activityDetails: trim(activityDetails),
    timeBucketId: timeBucketChild?.value,

    ...(company.identity.Aldo
      ? {
          earningTypeId: earningType?.value,
        }
      : {
          timeRanges: [
            {
              startAt: startAt.toISO(),
              endAt: endAt.toISO(),
              breakSeconds: mealBreak?.value && mealBreak.value * 60,
              timetypeId: timeType?.value,
              shiftTagIds: shiftTags?.map(shiftTag =>
                shiftTag.value.toString(),
              ),
            },
          ],
        }),
  }
}

export function formItemToUpdatePayload(
  item: ExistingTimesheetRecord,
  company: AppContextCompany,
): Gateway.UpdateTimeEntryPayload {
  const { startAt, endAt } = secondsToStartEnd(item)
  const {
    id,
    earningType,
    activityDetails,
    timeBucketChild,
    mealBreak,
    shiftTags,
    timeType,
    timeEntryTimeRangeId,
  } = item
  const { location, department, job } = item.position.data

  return {
    id,
    locationId: toNumber(location.id),
    departmentId: toNumber(department.id),
    roleId: toNumber(job.id),
    startAt: startAt.toISO(),
    endAt: endAt.toISO(),
    activityDetails: trim(activityDetails),
    // TODO: remove "ts-ignore" when null is available to be passed to the schema
    // @ts-ignore
    timeBucketId: timeBucketChild?.value ?? null,

    ...(company.identity.Aldo
      ? {
          // TODO: remove "ts-ignore" when null is available to be passed to the schema
          // @ts-ignore
          earningTypeId: earningType?.value ?? null,
        }
      : {
          timeRanges: [
            {
              breakSeconds: mealBreak?.value && mealBreak.value * 60,
              timetypeId: timeType?.value,
              shiftTagIds: shiftTags?.map(shiftTag =>
                shiftTag.value.toString(),
              ),

              // Update existing or create new time range
              ...(timeEntryTimeRangeId
                ? { id: timeEntryTimeRangeId }
                : {
                    startAt: startAt.toISO(),
                    endAt: endAt.toISO(),
                  }),
            },
          ],
        }),
  }
}

export function secondsToStartEnd({
  startDate,
  startSeconds,
  endSeconds,
  position,
}: DraftTimesheetRecord | ExistingTimesheetRecord) {
  const { location } = position.data

  const durationSeconds = secondsDuration(startSeconds, endSeconds)

  const startAt = startDate
    .plus({ seconds: startSeconds })
    .setZone(location.timezone, { keepLocalTime: true })
  const endAt = startAt.plus({ seconds: durationSeconds })

  return { startAt, endAt }
}

export function secondsDuration(
  startSeconds: number,
  endSeconds: number,
): number {
  return endSeconds > startSeconds
    ? endSeconds - startSeconds
    : FULL_DAY_SECONDS - startSeconds + endSeconds
}

export function timeSheetItemsDuration(
  timeSheetItems: { startSeconds: number; endSeconds: number }[],
) {
  const timeEntriesDuration = timeSheetItems.reduce((acc, entry) => {
    return acc + secondsDuration(entry.startSeconds, entry.endSeconds)
  }, 0)

  return timeEntriesDuration
}

export function timeoffsDuration(timeoffs: TimeoffsByCursorNode[]) {
  return timeoffs.reduce(
    (acc, timeoff) =>
      acc + Utils.DateTime.hoursToSeconds(timeoff.quantity.hours),
    0,
  )
}

export function getFieldsByDay(
  fields: FieldArrayWithId<TimesheetFormState, 'entries', 'formId'>[],
  days: DateTime[],
): FieldArrayItemWithInitialIndex[][] {
  const fieldsWithIndices = fields.map((item, index) => ({ ...item, index }))

  return days.map(day =>
    fieldsWithIndices.filter(item => item.startDate.hasSame(day, 'day')),
  )
}

export function getTimeoffsByDay(
  timeoffs: TimeoffsByCursorNode[],
  days: DateTime[],
  employeeTimezone: string,
): TimeoffsByCursorNode[][] {
  return days.map(day =>
    timeoffs
      .filter(tf => classifyTimeoffByDay(tf, day.toISODate(), employeeTimezone))
      .sort((a, b) => a.startAt.localeCompare(b.startAt)),
  )
}

export function classifyTimeoffByDay(
  timeoff: TimeoffsByCursorNode,
  day: string,
  employeeTimezone: string,
) {
  const startDatetime = DateTime.fromISO(timeoff.startAt).setZone(
    employeeTimezone,
  )
  if (!startDatetime.isValid) return false

  let start

  if (timeoff.partial) {
    start = startDatetime.toISODate()
  } else {
    start = DateTime.fromISO(timeoff.startAt, { setZone: true }).toISODate()
  }

  return day === start
}

export function getEffectiveRolesOptionsByDay(
  employeeJobs: JobEmployee[],
  days: DateTime[],
): PositionOption[][] {
  return days.map(day =>
    employeeJobs
      .filter(employeeJob => Utils.Role.isRoleEffectiveOnDate(employeeJob, day))
      .map(({ department, job, branch }) =>
        generatePositionOptions({ location: branch, department, job }),
      ),
  )
}

export function entriesToIntervals(entries: Array<FormTimesheetRecord>) {
  return entries
    .map(secondsToStartEnd)
    .map(({ startAt, endAt }) => Interval.fromDateTimes(startAt, endAt))
}

export function dayToInterval(day: DateTime) {
  return Interval.fromDateTimes(day.startOf('day'), day.endOf('day'))
}

export function entriesIntersectionWithDay(
  entryIntervals: Interval[],
  dayInterval: Interval,
): Interval[] {
  const targetDayEntryIntervals = entryIntervals.filter(interval =>
    interval.overlaps(dayInterval),
  )

  return compact(
    targetDayEntryIntervals.map(entryInterval =>
      entryInterval.intersection(dayInterval),
    ),
  )
}

// FIXME: core doesnt seem to work with just the ids sent
export function serverEntryToUpdatePayload(
  entry: TimeEntryReturn,
): Gateway.UpdateTimeEntryPayload {
  return {
    id: entry.id,

    locationId: toNumber(entry.location.id),
    departmentId: toNumber(entry.department.id),
    roleId: toNumber(entry.job.id),

    startAt: entry.startAt,
    endAt: entry.endAt,
    earningTypeId: entry.earningType?.id,
    activityDetails: entry.activityDetails,
  }
}

export function toId(record: ExistingTimesheetRecord | TimeEntryReturn) {
  return record.id
}

export function timeOffTimeTypeToOption(
  timeoff: TimeoffsByCursorNode,
): SelectOptionWithColor<string> | null {
  if (!timeoff.customLeaveDay.defaultTimeType) return null
  return {
    value: timeoff.customLeaveDay.defaultTimeType?.id,
    label: timeoff.customLeaveDay.defaultTimeType?.name,
    color: timeoff.customLeaveDay.defaultTimeType?.color,
  }
}
