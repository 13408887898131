import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useTheme } from 'styled-components'
import { LayoutProps, TypographyProps } from 'styled-system'

import { Box } from 'components/ui/__v2__/Grid'
import { Popover } from 'components/ui/__v2__/Popover'
import { TextOverflow } from 'components/ui/__v2__/TextOverflow'

type PopoverPlacement =
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'right-start'
  | 'right-end'
  | 'left-start'
  | 'left-end'

interface PopoverStyles {
  popoverMaxWidth?: number
  popoverPlacement?: PopoverPlacement
}

interface Props extends PopoverStyles, LayoutProps, TypographyProps {
  children: React.ReactNode
  color?: string
}

export function TextOverflowWithPopover({
  children,
  popoverPlacement = 'top',
  popoverMaxWidth = 300,
  ...rest
}: Props) {
  const { zIndices } = useTheme()
  const containerRef = useRef<HTMLDivElement>(null)
  const [showHelper, setShowHelper] = useState(false)

  const checkOverflow = useCallback(() => {
    if (!containerRef.current) return
    const hasOverflow =
      containerRef.current.scrollWidth > containerRef.current.clientWidth
    setShowHelper(hasOverflow)
  }, [])

  // Use ResizeObserver to detect changes in content size
  useEffect(() => {
    const element = containerRef.current
    if (!element) return

    checkOverflow()
    const resizeObserver = new ResizeObserver(checkOverflow)
    resizeObserver.observe(element)

    // eslint-disable-next-line consistent-return
    return () => {
      resizeObserver.disconnect()
    }
  }, [checkOverflow])

  return (
    // @ts-ignore
    <Popover
      content={
        <Box maxWidth={popoverMaxWidth} overflow="auto">
          {children}
        </Box>
      }
      disabled={!showHelper}
      interactive
      placement={popoverPlacement}
      withMaxHeight
      zIndex={zIndices.popup}
    >
      <TextOverflow ref={containerRef} showHelper={showHelper} {...rest}>
        {children}
      </TextOverflow>
    </Popover>
  )
}
