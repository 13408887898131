import { CustomLeaveDaysByCursorNode } from 'API/CustomLeaveDay/GraphQL'
import { DateTime } from 'luxon'
import { RRule } from 'rrule'

export function getCustomLeaveDaysByDay(
  customLeaveDays: CustomLeaveDaysByCursorNode[],
  days: DateTime[],
): CustomLeaveDaysByCursorNode[][] {
  return days.map(day =>
    customLeaveDays.filter(customLeaveDay => {
      if (!customLeaveDay.recurrence) return false
      const rrule = RRule.fromString(customLeaveDay.recurrence)
      return (
        rrule.between(
          day.toUTC().startOf('day').toJSDate(),
          day.toUTC().endOf('day').toJSDate(),
          true,
        ).length > 0
      )
    }),
  )
}
