import React, { useCallback, useMemo, useState } from 'react'

import { WidthProps } from 'styled-system'

import { API } from 'API'
import { TimetypeEssentialFieldsItem } from 'API/TimeTypes/GraphQL/Fragments/types'

import { TimetypeBadge } from 'components/ui/__v3__/Badge/TimetypeBadge'
import { Select } from 'components/ui/__v3__/Select'

import { i18n } from 'i18n'

import { TimetypeOption, ValueContainer } from './components'
import { timeTypeToOption } from './helpers'
import { TimePickerProps, TimeTypePickerOption } from './types'

type Props = WidthProps & TimePickerProps

export function TimeTypesPicker({
  value,
  isDisabled,
  onSelect,
  isMulti,
  withPortal = true,
  options,
  ...rest
}: Props) {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [timeTypes, setTimeTypes] = useState<TimetypeEssentialFieldsItem[]>([])

  const { loadTimeTypesPromise } = API.TimeTypes.picker()

  // NOTE: hack to render correct menu height for first render in scrollable view
  const handleMenuOpen = useCallback(async () => {
    if (options) {
      setMenuOpen(true)
      return
    }

    setLoading(true)

    const result = await loadTimeTypesPromise()
    setMenuOpen(false)

    setTimeTypes(result)

    setTimeout(() => {
      setLoading(false)
      setMenuOpen(true)
    }, 100)
  }, [options, loadTimeTypesPromise])

  const handleChange = isMulti
    ? (value: TimeTypePickerOption[]) => onSelect(value)
    : (value: TimeTypePickerOption | null) => {
        onSelect(value)
      }

  const defaultOptions = useMemo(
    () => options || timeTypes.map(timeTypeToOption),
    [options, timeTypes],
  )

  const formatOptions = useCallback(
    (option: TimeTypePickerOption) => (
      <TimetypeBadge color={option.color} label={option.label} />
    ),
    [],
  )

  return (
    <Select
      // @ts-ignore
      closeMenuOnSelect={!isMulti}
      customComponents={{ ValueContainer, Option: TimetypeOption }}
      formatOptionLabel={formatOptions}
      isClearable
      isDisabled={isDisabled}
      isLoading={isLoading}
      isMulti={isMulti}
      isSearchable={!isLoading}
      menuIsOpen={isMenuOpen}
      options={defaultOptions}
      placeholder={i18n('timeTypePicker.placeholder')}
      value={value}
      withPortal={withPortal}
      onChange={handleChange}
      onMenuClose={() => setMenuOpen(false)}
      onMenuOpen={handleMenuOpen}
      {...rest}
    />
  )
}
